// export const BACKEND_ADDR = '192.168.62.4:8000';
export const BACKEND_ADDR = '2455-135-181-80-216.ngrok-free.app';

// export const BACKEND_URL = 'http://' + BACKEND_ADDR;
export const BACKEND_URL = 'https://' + BACKEND_ADDR;

// export const WS_URL = 'ws://' + BACKEND_ADDR + '/ws';
export const WS_URL = 'wss://' + BACKEND_ADDR + '/ws';

export const API_URL = BACKEND_URL + '/api';
